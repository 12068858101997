import { Container } from '@goodfynd/react-web.layout.container';
import { styled } from '@goodfynd/react-web.theme';
import { Paper } from '@mui/material';

export const StyledContainer = styled('div', {
  width: '100%',
  height: '100%',
  backgroundSize: '100%',
});

export const StyledImage = styled('div', {
  width: '50%',
  height: '100%',
  top: 0,
  left: 0,
  bottom: 0,
  position: 'absolute',
  zIndex: 0,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '580px',
  backgroundPosition: 'left 50px',
  '@sm': {
    display: 'none',
  },
});
export const StyledMobileImage = styled('div', {
  display: 'none',
  width: '100%',
  height: '345px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',

  '@sm': {
    display: 'block',
  },
});

export const StyledFormContainer = styled(Paper, {
  maxWidth: '50%',
  margin: '50px 0px',
  padding: 32,
  zIndex: 3,
  '@sm': {
    '&.MuiPaper-root': {
      boxShadow: 'none',
      borderRadius: 0,
    },
    maxWidth: '100%',
    height: 'fit-content',
    margin: 0,
  },
});

export const StyledContent = styled('div', {
  justifyContent: 'end',
  display: 'flex',
  position: 'relative',

  '@sm': {
    position: 'unset',
    justifyContent: 'center',
    flexDirection: 'column',
  },
});

export const StyledContentWrapper = styled(Container, {
  '&.MuiContainer-root': {
    // display: 'flex',
    background: 'transparent',
    gap: 16,
    zIndex: 3,
    alignItems: 'center',

    '@sm': {
      display: 'grid',
      gridTemplateRows: 'auto auto',
      padding: 0,
    },
  },
});

export const StyledImageContainer = styled('div', {
  position: 'relative',
});
